/**
 * Extracts the last segment from a given path.
 *
 * @param path - The input path string.
 * @returns The last segment of the path.
 */
export function extractLastSegment(path: string): string {
  let trimmedPath = path.trim(); // Trim whitespace from the path

  if (trimmedPath.endsWith("/")) {
    trimmedPath = trimmedPath.slice(0, -1); // Remove the trailing slash
  }

  let startIndex = trimmedPath.lastIndexOf("/");
  if (startIndex !== -1) {
    startIndex =
      startIndex === trimmedPath.length - 1
        ? trimmedPath.lastIndexOf("/", startIndex - 1)
        : startIndex;
    return trimmedPath.slice(startIndex + 1);
  }
  return trimmedPath;
}
